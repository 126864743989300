import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import '../css/SpendAnalyzer.css';
import '../css/CommonLayout.css';
import { FaTrash } from 'react-icons/fa';
import { getFromLocalStorage, updateLocalStorage } from '../../utils/storageUtils';
import { Typography, Box, FormControl, Select, MenuItem } from '@mui/material';

// Helper function for getting next month-year
const getNextMonthYear = (currentMonthYear) => {
  const [month, year] = currentMonthYear.split('-').map(Number);
  if (month === 12) {
    return `01-${year + 1}`;
  }
  return `${String(month + 1).padStart(2, '0')}-${year}`;
};

// Global variables for month-year tracking
const today = new Date();

// Determine if today is within the last 5 days of the month
const isLastFiveDaysOfMonth = today.getDate() > new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate() - 5;

// Set default month and year based on the current date
const currentMonth = String(today.getMonth() + 1).padStart(2, '0');
const currentYear = String(today.getFullYear());
const previousMonth = today.getMonth() === 0 ? 12 : today.getMonth();
const previousYear = today.getMonth() === 0 ? today.getFullYear() - 1 : today.getFullYear();

// Always try to get existing values from localStorage first
let globalMonthYear = localStorage.getItem('globalMonthYear');
let globalNextMonthYear = localStorage.getItem('globalNextMonthYear');

// Only set default values if they don't exist in localStorage
if (!globalMonthYear || !globalNextMonthYear) {
    const defaultMonthYear = isLastFiveDaysOfMonth ? `${currentMonth}-${currentYear}` : `${String(previousMonth).padStart(2, '0')}-${previousYear}`;
    globalMonthYear = defaultMonthYear;
    globalNextMonthYear = getNextMonthYear(defaultMonthYear);
    
    // Store the default values
    localStorage.setItem('globalMonthYear', globalMonthYear);
    localStorage.setItem('globalNextMonthYear', globalNextMonthYear);
}

const categoryMapping = {
  Essentials: ['Rent', 'Groceries', 'Utilities', 'Transport'],
  Discretionary: ['Travel', 'Dining', 'Shopping', 'Subscriptions'],
  Debts: ['Personal Loan', 'Credit Card Payment', 'Car loan', 'Gold loan', 'Home loan'],
  'Short-term Savings': ['Short-term Savings', 'Emergency Fund'],
  'Long-term Savings': ['Long-term Savings', 'Long Term Savings', 'Investments', 'Pension'],
  'Emergency Fund': ['Emergency Fund'],
  Insurance: ['Term Insurance', 'Health Insurance', 'Car Insurance'],
  'Retirement Fund': ['Retirement Fund','Retirement Savings'],
  Others: []
};

const normalizeCategory = (category) => {
  // Only normalize the exact category name, not goal names
  if (category === 'Long Term Savings' || category === 'Long-term Savings') {
    // Check if this is a goal name by looking for spaces and capitalization pattern
    // Goal names typically have spaces and proper capitalization (e.g., "House Downpayment")
    if (category.includes(' ') && category !== 'Long Term Savings' && category !== 'Long-term Savings') {
      return category;  // Keep goal names as is
    }
    return 'Long-term Savings';
  }
  return category;
};

const getDefaultMapping = (category) => {
  const normalizedCategory = normalizeCategory(category);
  for (const [mainCategory, subCategories] of Object.entries(categoryMapping)) {
    if (subCategories.includes(normalizedCategory)) {
      return mainCategory;
    }
  }
  return 'Others';
};

const mainCategories = Object.keys(categoryMapping);

const predefinedCategories = [
  'Credit Card Payment',
  'Custom',
  'Dining',
  'Groceries',
  'Insurance',
  'Investments',
  'Loan',
  'Long-term Savings',
  'Others',
  'Pension',
  'Personal loan',
  'Rent',
  'Retirement Savings',
  'Shopping',
  'Short-term Savings',
  'Transport',
  'Travel',
  'Utilities'
];

const MONTHS = [
  { value: '', label: 'All Months' },
  ...Array.from({ length: 12 }, (_, i) => ({
    value: String(i + 1).padStart(2, '0'),
    label: new Date(2000, i).toLocaleString('default', { month: 'long' })
  }))
];

function SpendAnalyzer() {
  const [customCategories, setCustomCategories] = useState([]);
  const [customInputs, setCustomInputs] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [showMonthlyIncomeSection, setShowMonthlyIncomeSection] = useState(false);
  const [monthlyIncome, setMonthlyIncome] = useState('');
  const navigate = useNavigate();

  const [selectedDate] = useState({
    month: previousMonth,
    year: previousYear
  });

  // Set initial state for selected month and year
  const [selectedMonth, setSelectedMonth] = useState(isLastFiveDaysOfMonth ? currentMonth : String(previousMonth).padStart(2, '0'));
  const [selectedYear, setSelectedYear] = useState(isLastFiveDaysOfMonth ? currentYear : String(previousYear));
  const [expenses, setExpenses] = useState([{ category: '', categoryMapping: '', amount: '' }]);

  useEffect(() => {
    try {
      // Load data using global variables
      const savedCustomCategories = getFromLocalStorage('spendAnalyzer', 'customCategories', globalMonthYear);
      if (savedCustomCategories) {
        // Normalize any saved custom categories
        const normalizedCategories = Array.isArray(savedCustomCategories) 
          ? savedCustomCategories.map(category => normalizeCategory(category))
          : [];
        setCustomCategories(normalizedCategories);
      }

      const savedMonthlyIncome = getFromLocalStorage('spendAnalyzer', 'monthlyIncome', globalMonthYear);
      if (savedMonthlyIncome) {
        setMonthlyIncome(savedMonthlyIncome);
      }

      // Load and normalize any existing expenses
      const savedExpenses = getFromLocalStorage('spendAnalyzer', 'expenses', globalMonthYear);
      if (savedExpenses && Array.isArray(savedExpenses)) {
        const normalizedExpenses = savedExpenses.map(expense => {
          // Only preserve category name if it's a long-term savings goal
          if (expense.categoryMapping === 'Long-term Savings' && 
              expense.category !== 'Long Term Savings' && 
              expense.category !== 'Long-term Savings') {
            return {
              ...expense,
              categoryMapping: 'Long-term Savings'  // Ensure consistent mapping
            };
          }
          
          // For all other cases, normalize as before
          return {
            ...expense,
            category: normalizeCategory(expense.category),
            categoryMapping: expense.categoryMapping ? normalizeCategory(expense.categoryMapping) : getDefaultMapping(normalizeCategory(expense.category))
          };
        });
        setExpenses(normalizedExpenses);
      }
    } catch (error) {
      console.error('Error loading data:', error);
      setCustomCategories([]);
    }
  }, []);

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      const newMonthYear = `${selectedMonth}-${selectedYear}`;
      globalMonthYear = newMonthYear;
      globalNextMonthYear = getNextMonthYear(newMonthYear);

      // Store the global variables in localStorage
      localStorage.setItem('globalMonthYear', globalMonthYear);
      localStorage.setItem('globalNextMonthYear', globalNextMonthYear);

      const newCustomInputs = {};
      let formattedExpenses = [];

      // Load optimization table data using global variable
      const optimizationTableData = getFromLocalStorage('expenseTracker', 'optimizationTable', globalMonthYear);
      if (optimizationTableData && optimizationTableData.length > 0) {
        formattedExpenses = optimizationTableData.map((item, index) => {
          const normalizedCategory = normalizeCategory(item.category);
          const categoryExists = predefinedCategories.includes(normalizedCategory);
          
          if (!categoryExists && normalizedCategory) {
            newCustomInputs[index] = normalizedCategory;
            return {
              category: 'Custom',
              categoryMapping: getDefaultMapping(normalizedCategory),
              amount: item.amount || ''
            };
          }
          
          return {
            category: categoryExists ? normalizedCategory : '',
            categoryMapping: getDefaultMapping(normalizedCategory),
            amount: item.amount || ''
          };
        });
      }

      // Check for ShortTermSavings goals
      const shortTermGoals = getFromLocalStorage('shortTermSavings', 'shortTermGoals', globalMonthYear);
      console.log('Short Term Goals found:', shortTermGoals);
      
      if (shortTermGoals && shortTermGoals.length > 0) {
        // Add an entry for each short term goal
        shortTermGoals.forEach(goal => {
          if (goal.monthlyContribution && Number(goal.monthlyContribution) > 0) {
            const newIndex = formattedExpenses.length;
            newCustomInputs[newIndex] = goal.name || 'Short-term Goal';
            formattedExpenses.push({
              category: 'Custom',
              categoryMapping: 'Short-term Savings',
              amount: goal.monthlyContribution.toString()
            });
          }
        });
      }

      // Check for LongTermSavings goals - use the correct storage key
      const longTermGoals = getFromLocalStorage('longTermSavings', 'goals', globalMonthYear);
      console.log('Long Term Goals full data:', longTermGoals);
      
      if (longTermGoals && longTermGoals.length > 0) {
        // Add an entry for each long term goal
        longTermGoals.forEach(goal => {
          console.log('Processing long term goal:', goal);  // Log each goal's data
          console.log('Goal name:', goal.name);  // Specifically check if name exists
          
          if (goal.monthlyPayment && Number(goal.monthlyPayment) > 0) {
            const newIndex = formattedExpenses.length;
            const goalName = goal.name || 'Long-term Goal';
            console.log('Using goal name:', goalName);  // Log what name we're actually using
            newCustomInputs[newIndex] = goalName;
            formattedExpenses.push({
              category: goalName,
              categoryMapping: 'Long-term Savings',
              amount: goal.monthlyPayment.toString()
            });
          }
        });
      }

      // Also check budget data for long-term savings
      const budgetData = getFromLocalStorage('budget', 'budgetData', globalMonthYear);
      if (budgetData) {
        // Check both formats of the category name
        const longTermAmount = budgetData['Long-term Savings'] || budgetData['Long Term Savings'] || 0;
        if (longTermAmount > 0) {
          const newIndex = formattedExpenses.length;
          newCustomInputs[newIndex] = 'Long-term Savings';
          formattedExpenses.push({
            category: 'Long-term Savings',
            categoryMapping: 'Long-term Savings',
            amount: longTermAmount.toString()
          });
        }
      }

      // Load RepayDebts data
      const repayDebtsData = getFromLocalStorage('repayDebts', null, globalMonthYear);
      console.log('RepayDebts data found:', repayDebtsData);

      // Use calculationResults instead of loanSummaries
      const calculationResults = repayDebtsData?.calculationResults || [];

      if (calculationResults.length > 0) {
        // Process calculationResults
        calculationResults.forEach(loan => {
          if (loan.originalEMI && Number(loan.originalEMI) > 0) {
            const newIndex = formattedExpenses.length;
            newCustomInputs[newIndex] = loan.loanType;
            formattedExpenses.push({
              category: loan.loanType,
              categoryMapping: 'Debts',
              amount: loan.originalEMI.toString()
            });
          }
        });
      }

      // Check for extra debt payment
      if (repayDebtsData?.extraPayment && Number(repayDebtsData.extraPayment) > 0) {
        const newIndex = formattedExpenses.length;
        newCustomInputs[newIndex] = 'Extra Paid Debt';
        formattedExpenses.push({
          category: 'Custom',
          categoryMapping: 'Debts',
          amount: repayDebtsData.extraPayment.toString()
        });
      }

      // Check for Retirement data
      const retirementData = getFromLocalStorage('retirement', 'retirement', globalMonthYear);
      console.log('Retirement data found:', retirementData);

      if (retirementData?.monthlyContribution && Number(retirementData.monthlyContribution) > 0) {
        formattedExpenses.push({
          category: 'Retirement Savings',
          categoryMapping: 'Retirement Fund',
          amount: retirementData.monthlyContribution.toString()
        });
      }

      // Check for Emergency Fund data
      const monthlyContribution = getFromLocalStorage('emergencyFund', 'monthlyContribution', globalMonthYear);
      console.log('Emergency Fund monthly contribution found:', monthlyContribution);

      if (monthlyContribution && Number(monthlyContribution) > 0) {
        const newIndex = formattedExpenses.length;
        newCustomInputs[newIndex] = 'Emergency Fund';
        formattedExpenses.push({
          category: 'Custom',
          categoryMapping: 'Emergency Fund',
          amount: monthlyContribution.toString()
        });
      }
      
      // Set the state with all collected data
      setCustomInputs(newCustomInputs);
      setExpenses(formattedExpenses.length > 0 ? formattedExpenses : [{ category: '', categoryMapping: '', amount: '' }]);
    }
  }, [selectedMonth, selectedYear]);

  const handleExpenseChange = (index, field, value) => {
    const updatedExpenses = [...expenses];
    
    if (field === 'category') {
      // Check if this is a long-term savings goal name
      const isLongTermGoal = expenses[index].categoryMapping === 'Long-term Savings' && 
                            value !== 'Long Term Savings' && 
                            value !== 'Long-term Savings';
      
      // Only normalize if it's not a goal name
      const normalizedValue = isLongTermGoal ? value : normalizeCategory(value);
      
      if (normalizedValue === 'Custom') {
        const updatedCustomInputs = { ...customInputs, [index]: '' };
        setCustomInputs(updatedCustomInputs);
        updateLocalStorage('spendAnalyzer', { customInputs: updatedCustomInputs }, globalMonthYear);
        return;
      }
      
      setCustomInputs(prev => {
        const updated = {...prev};
        delete updated[index];
        return updated;
      });

      const isDuplicate = expenses.some((exp, i) => 
        i !== index && normalizeCategory(exp.category) === normalizedValue && normalizedValue !== ''
      );
      
      if (isDuplicate) {
        setErrorMessage('Categories should be unique');
        return;
      }
      
      updatedExpenses[index].category = normalizedValue;
      // Keep the existing categoryMapping for long-term savings goals
      if (!isLongTermGoal) {
        updatedExpenses[index].categoryMapping = getDefaultMapping(normalizedValue);
      }
    } else {
      updatedExpenses[index][field] = value;
    }
    
    if (!updatedExpenses[index].category) updatedExpenses[index].category = '';
    if (!updatedExpenses[index].amount) updatedExpenses[index].amount = '';
    
    setExpenses(updatedExpenses);
    updateLocalStorage('spendAnalyzer', { expenses: updatedExpenses }, globalMonthYear);
    setErrorMessage('');
  };

  const handleDeleteExpense = (index) => {
    const updatedExpenses = expenses.filter((_, i) => i !== index);
    setExpenses(updatedExpenses);
    updateLocalStorage('spendAnalyzer', { expenses: updatedExpenses }, globalMonthYear);
  };

  const handleAddExpense = () => {
    const newExpense = {
      category: '',
      categoryMapping: '',
      amount: ''
    };
    
    const updatedExpenses = [...expenses, newExpense];
    setExpenses(updatedExpenses);
    
    setCustomInputs(prev => {
      const updated = { ...prev };
      delete updated[expenses.length];
      return updated;
    });
    
    updateLocalStorage('spendAnalyzer', { expenses: updatedExpenses }, globalMonthYear);
  };

  const handleKeyDown = (event, index, field) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      
      if (field === 'amount') {
        handleAddExpense();
        setTimeout(() => {
          const inputs = document.querySelectorAll('.input-select, .input-number');
          const nextInput = inputs[inputs.length - 3];
          if (nextInput) nextInput.focus();
        }, 0);
      } else {
        const inputs = document.querySelectorAll('.input-select, .input-number');
        const currentIndex = Array.from(inputs).indexOf(event.target);
        const nextInput = inputs[currentIndex + 1];
        if (nextInput) nextInput.focus();
      }
    }
  };

  const handleCustomCategoryInput = (index, value) => {
    const updatedCustomInputs = { ...customInputs, [index]: value };
    setCustomInputs(updatedCustomInputs);
    updateLocalStorage('spendAnalyzer', { customInputs: updatedCustomInputs }, globalMonthYear);
  };

  const handleCustomCategoryConfirm = (index, value) => {
    if (value.trim()) {
      const updatedExpenses = [...expenses];
      updatedExpenses[index].category = value.trim();
      setExpenses(updatedExpenses);
      updateLocalStorage('spendAnalyzer', { expenses: updatedExpenses }, globalMonthYear);
      
      const updatedCustomInputs = { ...customInputs };
      delete updatedCustomInputs[index];
      setCustomInputs(updatedCustomInputs);
      updateLocalStorage('spendAnalyzer', { customInputs: updatedCustomInputs }, globalMonthYear);
      
      const updatedCustomCategories = [...customCategories, value.trim()];
      setCustomCategories(updatedCustomCategories);
      updateLocalStorage('spendAnalyzer', { customCategories: updatedCustomCategories }, globalMonthYear);
    }
  };

  const handleConfirmSpends = () => {
    const hasEmptyFields = expenses.some((exp) => {
      const categoryValue = exp.category === 'Custom' ? customInputs[expenses.indexOf(exp)] : exp.category;
      return !categoryValue || !exp.amount;
    });

    if (hasEmptyFields) {
      setErrorMessage('Please fill all fields before confirming');
      return;
    }

    const formattedExpenses = expenses.map((exp, index) => ({
      category: exp.category === 'Custom' ? customInputs[index] : exp.category,
      categoryMapping: exp.categoryMapping,
      amount: exp.amount
    }));

    // Save the formatted expenses using global variable
    updateLocalStorage('spendAnalyzer', { expenses: formattedExpenses }, globalMonthYear);
    setErrorMessage('');
    setShowMonthlyIncomeSection(true);
  };

  const handleConfirmIncome = () => {
    if (!monthlyIncome) {
      return;
    }

    // Save monthly income for both current and next month using global variables
    updateLocalStorage('spendAnalyzer', { monthlyIncome }, globalMonthYear);
    updateLocalStorage('spendAnalyzer', { monthlyIncome }, globalNextMonthYear);

    navigate('/spend-analysis-summary', {
      state: {
        spendData: expenses.map(exp => ({
          ...exp,
          amount: Number(exp.amount)
        })),
        income: monthlyIncome,
        monthYear: globalMonthYear,
        nextMonthYear: globalNextMonthYear
      }
    });
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [{ value: '', label: 'All Years' }];
    for (let year = currentYear; year >= currentYear - 4; year--) {
      years.push({ value: String(year), label: String(year) });
    }
    return years;
  };

  const handleMonthChange = (e) => {
    const newMonth = e.target.value.padStart(2, '0');
    if (newMonth !== selectedMonth) {
      setSelectedMonth(newMonth);
      
      // Update global variables
      const newMonthYear = `${newMonth}-${selectedYear}`;
      globalMonthYear = newMonthYear;
      globalNextMonthYear = getNextMonthYear(newMonthYear);

      // Store the global variables in localStorage
      localStorage.setItem('globalMonthYear', globalMonthYear);
      localStorage.setItem('globalNextMonthYear', globalNextMonthYear);

      // Fetch data using global variable
      const optimizationTableData = getFromLocalStorage('expenseTracker', 'optimizationTable', globalMonthYear);
      console.log('Optimization table data after month change:', optimizationTableData);
      
      const newCustomInputs = {};
      let formattedExpenses = [];

      if (optimizationTableData && optimizationTableData.length > 0) {
        formattedExpenses = optimizationTableData.map((item, index) => {
          const categoryExists = predefinedCategories.includes(item.category);
          
          if (!categoryExists && item.category) {
            newCustomInputs[index] = item.category;
            return {
              category: 'Custom',
              categoryMapping: item.categoryMapping || getDefaultMapping(item.category),
              amount: item.amount || ''
            };
          }
          
          return {
            category: categoryExists ? item.category : '',
            categoryMapping: item.categoryMapping || getDefaultMapping(item.category),
            amount: item.amount || ''
          };
        });
      }

      setCustomInputs(newCustomInputs);
      setExpenses(formattedExpenses.length > 0 ? formattedExpenses : [{ category: '', categoryMapping: '', amount: '' }]);
    }
  };

  const handleYearChange = (e) => {
    const newYear = e.target.value;
    if (newYear !== selectedYear) {
      setSelectedYear(newYear);
      
      // Update global variables
      const newMonthYear = `${selectedMonth}-${newYear}`;
      globalMonthYear = newMonthYear;
      globalNextMonthYear = getNextMonthYear(newMonthYear);

      // Store the global variables in localStorage
      localStorage.setItem('globalMonthYear', globalMonthYear);
      localStorage.setItem('globalNextMonthYear', globalNextMonthYear);

      // Fetch data using global variable
      const optimizationTableData = getFromLocalStorage('expenseTracker', 'optimizationTable', globalMonthYear);
      console.log('Optimization table data after year change:', optimizationTableData);
      
      const newCustomInputs = {};
      let formattedExpenses = [];

      if (optimizationTableData && optimizationTableData.length > 0) {
        formattedExpenses = optimizationTableData.map((item, index) => {
          const categoryExists = predefinedCategories.includes(item.category);
          
          if (!categoryExists && item.category) {
            newCustomInputs[index] = item.category;
            return {
              category: 'Custom',
              categoryMapping: item.categoryMapping || getDefaultMapping(item.category),
              amount: item.amount || ''
            };
          }
          
          return {
            category: categoryExists ? item.category : '',
            categoryMapping: item.categoryMapping || getDefaultMapping(item.category),
            amount: item.amount || ''
          };
        });
      }

      setCustomInputs(newCustomInputs);
      setExpenses(formattedExpenses.length > 0 ? formattedExpenses : [{ category: '', categoryMapping: '', amount: '' }]);
    }
  };

  const updateExpensesFromOptimizationData = (optimizationTableData) => {
    if (optimizationTableData && optimizationTableData.length > 0) {
      const newCustomInputs = {};
      const formattedExpenses = optimizationTableData.map((item, index) => {
        const categoryExists = predefinedCategories.includes(item.category);
        
        if (!categoryExists && item.category) {
          newCustomInputs[index] = item.category;
          return {
            category: 'Custom',
            categoryMapping: item.categoryMapping || getDefaultMapping(item.category),
            amount: item.amount || ''
          };
        }
        
        return {
          category: categoryExists ? item.category : '',
          categoryMapping: item.categoryMapping || getDefaultMapping(item.category),
          amount: item.amount || ''
        };
      });
      
      setCustomInputs(newCustomInputs);
      setExpenses(formattedExpenses);
    } else {
      setExpenses([{ category: '', categoryMapping: '', amount: '' }]);
      setCustomInputs({});
    }
  };

  return (
    <div className="page-container">
      <div className="progress-section">
        <ProgressBar />
      </div>
      
      <div className="content-container">
        <h2>Spend Analyzer</h2>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Typography variant="h6" gutterBottom>
            Track and categorize your expenses to understand your spending patterns. Add your expense categories and corresponding amounts below.
          </Typography>
        </div>

        <Box className="date-filter-container" sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography className="filter-label" variant="subtitle1">Analyze spends for:</Typography>
          
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              value={selectedMonth || ''}
              onChange={handleMonthChange}
              displayEmpty
            >
              {MONTHS.map((month) => (
                <MenuItem key={month.value} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <Select
              value={selectedYear || ''}
              onChange={handleYearChange}
              displayEmpty
            >
              {generateYearOptions().map((year) => (
                <MenuItem key={year.value} value={year.value}>
                  {year.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <div className="spend-analyzer-wrapper">
          <div className="spend-analyzer-page">
            <div className="scrollable-content">
              <div className="spend-form">
                <table className="spend-analyzer-table">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Category Mapping</th>
                      <th>Amount (₹)</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenses.map((expense, index) => (
                      <tr key={index}>
                        <td>
                          {customInputs.hasOwnProperty(index) ? (
                            <input
                              type="text"
                              className="input-select"
                              value={customInputs[index]}
                              onChange={(e) => handleCustomCategoryInput(index, e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleCustomCategoryConfirm(index, customInputs[index]);
                                  handleKeyDown(e, index, 'category');
                                }
                              }}
                              placeholder="Enter custom category"
                              autoFocus
                            />
                          ) : (
                            <select
                              className="input-select"
                              value={expense.category}
                              onChange={(e) => handleExpenseChange(index, 'category', e.target.value)}
                              onKeyDown={(e) => handleKeyDown(e, index, 'category')}
                            >
                              <option value="">Select Category</option>
                              {predefinedCategories.map((category) => (
                                <option key={category} value={category}>
                                  {category}
                                </option>
                              ))}
                              {customCategories.map((category) => (
                                <option key={category} value={category}>
                                  {category}
                                </option>
                              ))}
                            </select>
                          )}
                        </td>
                        <td>
                          <select
                            className="input-select"
                            value={expense.categoryMapping || getDefaultMapping(expense.category)}
                            onChange={(e) => handleExpenseChange(index, 'categoryMapping', e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, index, 'categoryMapping')}
                          >
                            <option value="">Select Mapping</option>
                            {mainCategories.map((category) => (
                              <option key={category} value={category}>
                                {category}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            className="input-number"
                            value={expense.amount}
                            onChange={(e) => handleExpenseChange(index, 'amount', e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, index, 'amount')}
                            placeholder="Enter amount"
                          />
                        </td>
                        <td>
                          <button
                            className="delete-button"
                            onClick={() => handleDeleteExpense(index)}
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="button-container">
                  <button 
                    type="button" 
                    onClick={handleAddExpense} 
                    className="spend-analyzer-add-button"
                  >
                    Add More
                  </button>
                  <button 
                    type="button" 
                    className="analyze-button"
                    onClick={handleConfirmSpends}
                  >
                    Confirm my spends
                  </button>
                </div>
              </div>
            </div>
          </div>

          {showMonthlyIncomeSection && (
            <div className="spend-analyzer-income-section">
              <p>Enter your monthly income</p>
              <input
                type="number"
                value={monthlyIncome}
                onChange={(e) => setMonthlyIncome(e.target.value)}
                className="input-number"
                placeholder="Enter monthly income"
              />
              <button
                type="button"
                onClick={handleConfirmIncome}
                className="analyze-button"
              >
                Confirm my income
              </button>
            </div>
          )}

          {errorMessage && (
            <div className="spend-analyzer-error">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SpendAnalyzer;
